<template>
    <a href="javascript:0;"
        data-toggle="popover"
        :title="getTitleText"
        data-trigger="hover"
        data-placement="top"
        data-html="true"
        :data-content="getPriceText">
        <i class="fa fa-info-circle"></i>
    </a>

</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'price-help',
    	props: {
    		pricelist: Array
    	},
    	mounted: function() {
    		$('[data-toggle="popover"]').popover();
    	},
    	computed: {
    		getTitleText: function() {
    			var titleText = '';
    			if (this.pricelist) {
    				titleText = 'Obligatorische Nebenkosten';
    			} else {
    				titleText = 'Zusätzliche Kosten';
    			}
    			return titleText;
    		},
    		getPriceText: function() {
    			console.log('pricehelp@pricelist::' + this.pricelist);
    			var priceText = '';
    			if (this.pricelist) {

    				var i;
    				priceText = "<ul>";
    				for (i = 0; i < this.pricelist.length; i++) {

    					if (this.pricelist[i].type == 'FINALCLEANING') {
    						priceText += "<li>";
    						priceText += "Endreinigung " + this.pricelist[i].value / 100 + "€";
    						priceText += "</li>";
    					}


    					//console.log('type:' + this.pricelist[i].name);
    				}
    			} else {
    				//console.log('setting price as static text.........');
    				priceText = 'Zusätzliche Kosten können anfallen';
    				/*priceText = "<ul><li>";
    				priceText += "Miete 100 €";
    				priceText += "<li>";
    				priceText += "Endreinigung 25 €";
    				priceText + "</li>";

    				priceText += "</li></ul>";*/
    			}

    			return priceText;
    		}
    	}
    };
</script>