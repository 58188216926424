<template>
    <span>
        {{getCalculatedValue()}}
    </span>
</template>
<script>
    import V from 'voUtils/V.js';


    export default {
    	voVueComponent: 'currency-mod',
    	props: {
    		amount: {
    			type: [Number, String, Object]
    		},
    	},

    	methods: {
    		getCalculatedValue: function() {
    			if (!this.amount) {
    				return '';
    			}
    			var calcPrice = 0;
    			calcPrice = Number((this.amount) / 100).toLocaleString("de-DE", {
    				minimumFractionDigits: 2
    			}) + " €";
    			return calcPrice;
    		}
    	},
    	computed: {

    	}
    };
</script>